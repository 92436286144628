var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "3" } }, [_vm._v("Totaal:")]),
          _c("v-col", { attrs: { cols: "1", align: "right" } }, [
            _vm._v(_vm._s(_setup.totals.total)),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "3" } }, [
            _vm._v(
              "Totaal vorige week " + _vm._s(_setup.totals.pastWeekNumber) + ":"
            ),
          ]),
          _c("v-col", { attrs: { cols: "1", align: "right" } }, [
            _vm._v(_vm._s(_setup.totals.totalPastWeek)),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "3" } }, [
            _vm._v(
              "Totaal vorige maand " +
                _vm._s(_setup.totals.pastMonthNumber) +
                ":"
            ),
          ]),
          _c("v-col", { attrs: { cols: "1", align: "right" } }, [
            _vm._v(_vm._s(_setup.totals.totalPastMonth)),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "3" } }, [
            _vm._v(
              "Totaal vorige kwartaal " +
                _vm._s(_setup.totals.pastQuarterNumber) +
                ":"
            ),
          ]),
          _c("v-col", { attrs: { cols: "1", align: "right" } }, [
            _vm._v(_vm._s(_setup.totals.totalPastQuarter)),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "overview-filters align-items-start" },
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c("filter-set", {
                attrs: {
                  name: _setup.FilterDataName.UserLoginLog,
                  filters: _setup.filters,
                  "pre-filters": _vm.preFilter,
                },
                on: {
                  "filter-search": _setup.handleFilterSearch,
                  "filters-changed27": _setup.handleFiltersChanged,
                },
              }),
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-col",
            { staticClass: "text-right", attrs: { cols: "auto" } },
            [
              _setup.props.addLink
                ? _c(
                    "v-btn",
                    {
                      staticClass: "primary mt-4",
                      attrs: { dark: "", to: _setup.props.addLink },
                    },
                    [
                      _c("v-icon", { attrs: { dark: "", center: "" } }, [
                        _vm._v("mdi-plus"),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "text-right", attrs: { cols: "auto" } },
            [_vm._t("add-relation")],
            2
          ),
        ],
        1
      ),
      _c("v-data-table", {
        attrs: {
          options: _setup.gridOptions,
          headers: _setup.headers,
          "hide-default-footer": "",
          items: _setup.gridData,
          loading: _setup.gridIsLoading,
          "items-per-page": _setup.pageSize,
          "server-items-length": _setup.totalItems,
        },
        on: {
          "update:options": [
            function ($event) {
              _setup.gridOptions = $event
            },
            _setup.changedOptions,
          ],
        },
        scopedSlots: _vm._u(
          [
            {
              key: `item.actions`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        to: {
                          name: "IAM-UserDetails",
                          params: { userId: item.id },
                        },
                        icon: "",
                        small: "",
                        plain: "",
                      },
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v(" mdi-table "),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: `item.timeStamp`,
              fn: function ({ item }) {
                return [
                  _vm._v(
                    " " + _vm._s(_setup.formatDateUtcDT(item.timeStamp)) + " "
                  ),
                ]
              },
            },
            {
              key: `item.userId`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        to: {
                          name: "IAM-UserDetails",
                          params: { userId: item.userId },
                        },
                        icon: "",
                        small: "",
                        plain: "",
                      },
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v(" mdi-table "),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: `item.resourceId`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        to: {
                          name: "IAM-ResourceDetails",
                          params: { resourceId: item.resourceId },
                        },
                        icon: "",
                        small: "",
                        plain: "",
                      },
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v(" mdi-table "),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: `item.sourceReferenceLink`,
              fn: function ({ item }) {
                return [
                  item.sourceReference
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                to: {
                                  name: "Party",
                                  params: {
                                    partyReference: item.sourceReference,
                                    addModus: false,
                                  },
                                },
                                icon: "",
                                small: "",
                                plain: "",
                              },
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v(" mdi-table "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
      _c(_setup.GridPagination, {
        attrs: {
          pageNumber: _setup.pageNumber,
          pageSize: _setup.pageSize,
          pageCount: _setup.pageCount,
        },
        on: { changedPaging: _setup.changedPaging },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }